/*
	jQuery.mmenu iconpanels addon CSS
*/

@import "../_inc/variables";

.mm-iconpanel .mm-panel
{
	-webkit-transition-property: -webkit-transform, left, right;
	-moz-transition-property: -moz-transform, left, right;
	-ms-transition-property: -ms-transform, left, right;
	-o-transition-property: -o-transform, left, right;
	transition-property: transform, left, right;

	&.mm-opened
	{
		border-left: 1px solid;
		border-color: inherit;
	}
	&.mm-subopened
	{
		overflow-y: hidden;
		left: -$mm_iconpanelWidth;
		right: $mm_iconpanelWidth;

		@include mm_webkit_prefix( 'transform', translate3d( 0, 0, 0 ) );
	}
}

@include mm_iconpanel_positioning( 0 );
@include mm_iconpanel_positioning( 1 );
@include mm_iconpanel_positioning( 2 );
@include mm_iconpanel_positioning( 3 );
@include mm_iconpanel_positioning( 4 );
@include mm_iconpanel_positioning( 5 );
@include mm_iconpanel_positioning( 6 );


.mm-subblocker
{
	background: inherit;
	opacity: 0;
	display: block;

	@include mm_webkit_prefix( 'transition', opacity $mm_transitionDuration $mm_transitionFunction );
}

.mm-subopened .mm-subblocker
{
	opacity: 0.6;
	position: absolute;
	top: 0;
	right: 0;
	bottom: -100000px;
	left: 0;
	z-index: 3;
}