@charset "UTF-8";

$unoFontDir: "../../fonts/uno";

@font-face {
  font-family: "uno";
  src: url("#{$unoFontDir}/uno.eot");
  src: url("#{$unoFontDir}/uno.eot?#iefix") format("embedded-opentype"),
  url("#{$unoFontDir}/uno.woff") format("woff"),
  url("#{$unoFontDir}/uno.ttf") format("truetype"),
  url("#{$unoFontDir}/uno.svg#uno") format("svg");
  font-weight: normal;
  font-style: normal;

}

[class^="uno-icon-"],
[class*=" uno-icon-"] {
  font-size: inherit;
  display: inline-block;
}

[data-icon]:before {
  font-family: "uno" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="uno-icon-"]:before,
[class*=" uno-icon-"]:before {
  font-family: "uno" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.uno-icon-chat:before {
  content: "a";
}

.uno-icon-picture:before {
  content: "b";
}

.uno-icon-versions:before {
  content: "c";
}

.uno-icon-bank-notes:before {
  content: "d";
}

.uno-icon-newspaper:before {
  content: "e";
}

.uno-icon-money:before {
  content: "g";
}

.uno-icon-card-hand:before {
  content: "i";
}

.uno-icon-e-wallet:before {
  content: "j";
}

.uno-icon-game:before {
  content: "k";
}

.uno-icon-idd-call:before {
  content: "l";
}

.uno-icon-mobile-topup:before {
  content: "m";
}

.uno-icon-admins:before {
  content: "f";
}

.uno-icon-admin-add:before {
  content: "h";
}

.uno-icon-admin:before {
  content: "n";
}

.uno-icon-map:before {
  content: "o";
}

.uno-icon-layout:before {
  content: "p";
}

.uno-icon-windows:before {
  content: "q";
}

.uno-icon-page-multiple:before {
  content: "r";
}

