/*
	jQuery.mmenu tileview extension CSS
*/

@import "../_inc/variables";


.mm-menu
{
	&.mm-tileview .mm-listview,
	.mm-tileview.mm-listview
	{
		@include mm_clearfix;

		> li
		{
			width: 50%;
			height: 0;
			padding: 50% 0 0 0;
			float: left;
			position: relative;

			&:after
			{
				left: 0;
				top: 0;
				border-right-width: 1px;
				border-right-style: solid;
				z-index: -1;
			}

			&.mm-tile-xs
			{
				width: 12.5%;
				padding-top: 12.5%;
			}
			&.mm-tile-s
			{
				width: 25%;
				padding-top: 25%;
			}
			&.mm-tile-l
			{
				width: 75%;
				padding-top: 75%;
			}
			&.mm-tile-xl
			{
				width: 100%;
				padding-top: 100%;
			}

			> a,
			> span
			{
				line-height: 1px;
				text-align: center;
				padding: 50% $mm_padding 0 $mm_padding;
				margin: 0;
				position: absolute;
				top: 0;
				right: 1px;
				bottom: 1px;
				left: 0;
			}
			> .mm-next
			{
				width: auto;

				&:before,
				&:after
				{
					content: none;
					display: none;
				}
			}
		}
	}

	&.mm-tileview
	{
		.mm-panel
		{
			padding-left: 0;
			padding-right: 0;

			&:after
			{
				content: none;
				display: none;
			}
		}
		.mm-listview
		{
			margin: 0;
		}
	}
}