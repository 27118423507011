/*
	jQuery.mmenu borderstyle extension CSS
*/

@import "../_inc/variables";


.mm-menu.mm-border-none .mm-listview > li,
.mm-listview.mm-border-none > li
{
	&:after
	{
		content: none;
	}
}
.mm-menu.mm-border-full .mm-listview > li,
.mm-listview.mm-border-full > li
{
	&:after
	{
		left: 0 !important;
	}
}
