/*
	jQuery.mmenu multiline extension CSS
*/

@import "../_inc/variables";


.mm-menu.mm-multiline .mm-listview > li,
.mm-listview.mm-multiline > li
.mm-listview > li.mm-multiline
{
	> a,
	> span
	{
		text-overflow: clip;
		white-space: normal;
	}
}