/*
	jQuery.mmenu autoHeight addon CSS
*/

@import "../_inc/variables";


.mm-menu
{
	&.mm-top,
	&.mm-bottom
	{		
		&.mm-autoheight
		{
			max-height: percentage( $mm_menuHeight );
			
			&.mm-fullscreen
			{
				max-height: 100%;
			}
		}
	}
	&.mm-measureheight > .mm-panel
	{
		bottom: auto !important;
		height: auto !important;
	}
}