/*
	jQuery.mmenu dividers addon CSS
*/

@import "../_inc/variables";



//	Collapsed
.mm-divider
{
	> span
	{
		@include mm_ellipsis;
		padding: 0;
		line-height: $mm_dividerHeight;
	}

	&.mm-opened a.mm-next:after
	{
		@include mm_webkit_prefix( "transform", rotate( 45deg ) );
	}
}
.mm-collapsed:not( .mm-uncollapsed )
{
	display: none;
}


//	Fixed
.mm-fixeddivider
{
	background: inherit;
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 2;
	
	//	Bugfix
	@include mm_webkit_prefix( 'transform', translate3d( 0, 0, 0 ) );
	
	&:after
	{
		content: none !important;
		display: none !important;
	}
}
.mm-hasdividers .mm-fixeddivider
{
	display: block;
}


@include mm_colors_dividers;